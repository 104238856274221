
.stats canvas{
    max-height: 402px;
}

.card-footer{
    border-radius: 0 0px 20px 20px !important;
}

.custom-tooltip, .custom-tooltip2 {
    border-radius: 5.322px;
    background: #FFF;
    box-shadow: 0px 4px 13.31px 0px rgba(0, 0, 0, 0.15);
    padding: 0px;
    position: absolute;
    opacity: 0;
    transform: translate(-155px,15px);
    transition: opacity 0.1s ease;
    width: 200px;
  }
  
  .tooltip-content {
    color: black;
  }

  .custom-tooltip a,
  .custom-tooltip2 a{
    display: block;
    border-radius: 0px 0px 5.32px 5.32px;
    background: var(--2-secondary-s-blue, #D9E8EE);
    padding: 10px;
    font-size: 12px;
    margin: 0 auto;
    text-align: center;
  }

  .tooltip-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }

  .custom-tooltip::after,
  .custom-tooltip2::after {
    content: "";
    position: absolute;
    top: 42%;
    left: -8%;
    margin-left: -5px;
    border-width: 12px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    transform: rotate(90deg);
}

.tooltip {
  position: relative;
  background: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 390px;
  bottom: 100%;
  left: 50%;
  margin-left: -195px;
  background-color: #494242;
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 999;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.5px;
  font-family: sans-serif;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.image_upload{
  width: 220px;
  height: auto;
  margin-left: 1rem;
}

.custom-image-upload.org .img_upload{
  position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    top: auto;
    margin: 0 auto;
    text-align: center;
}

.dashboard.jetadmin .page__main {
  margin: 0px 0px 0px 0px !important;
  padding-top: 0;
}

.dashboard.jetadmin .page__main .dashboard {
  padding: 15px 0px;
}

.bg_primary {
  background-color: #146B90 !important;
  border-radius: 8px;
}

.bg_green {
  background-color: #22A298;
  border-radius: 8px;
}

.bg_yellow {
  background-color: #F4B532;
  border-radius: 8px;
}

.bg_red {
  background-color: #ED483D;
  border-radius: 8px;
}

.bg_pink {
  background-color: #F59390;
  border-radius: 8px;
}

a.btn-link.export {
  border-radius: 4px;
  border: 1px solid var(--1-primary-colors-blue, #146B90);
  background: var(--3-text-colors-white, #FFF);
  color: #146b90;
  padding: 8px 55px;
  text-decoration: none;
}

.react-datepicker__portal {
  position: absolute !important;
  width: 150px !important;
  min-width: 150px !important;
  height: auto !important;
  background-color: transparent !important;
  left: auto !important;
  top: auto !important;
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 0.944rem !important;
}

.react-datepicker__input-container input {
  outline: none;
  border: 1px solid #ddd;
  display: block;
  width: 100%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzIxMjUyOSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+) !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  position: relative;
  cursor: pointer;
}

.box {
  width: 100%;
  padding: 20px;
}

.subbox {
  width: 100%;
  max-height: 300px;
  overflow-y: auto; 
}

  

  


  