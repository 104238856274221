.reminders{position: relative;}

.reminders.dropdown-toggle::after {
    content: " ";
    position: absolute;
    background-repeat: no-repeat;
    background-size: 16px;
    width: 20px;
    height: 20px;
    right: 25px;
    left: auto;
    top: 14px;
    bottom: auto;
    border: 0;
    cursor: pointer;
  }
  
  .reminders.dropdown-toggle[aria-expanded="true"]::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkZyYW1lIDgyMSI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0zLjMwNTM0IDE2Ljg0NzdMMTIuMzMyOCA3LjgyMDE1TDIxLjM2MDQgMTYuODQ3NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyLjcwODI1IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+Cjwvc3ZnPgo=);
  }
  
  .reminders.dropdown-toggle[aria-expanded="false"]::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkZyYW1lIDgyMSI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0zLjMwNTM0IDcuODIwMzFMMTIuMzMyOCAxNi44NDc4TDIxLjM2MDQgNy44MjAzMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyLjcwODI1IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+Cjwvc3ZnPgo=);
  }

  ul.dropdown-menu {
    border-radius: 0px 0px 8px 8px;
    background: #FFF;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
    width: 215px;
    text-align: center;
  }

  ul.dropdown-menu li:last-child {
    border-bottom: none;
  }

  ul.dropdown-menu li {
    border-bottom: 1px solid #ccc;
    margin: 0px 7px;
    padding: 5px 0;
}

.badge {
  padding: 6.667px 15px !important;
  font-size: 10px !important;
  width: 100px;
}

.inprogress-badge {
  background: rgba(20, 107, 144, 0.18);
  color: var(--1-primary-colors-blue, #146B90) !important;
}

.completed-badge{
  color: var(--1-primary-colors-cyan, #22A298) !important;
  background: rgba(34, 162, 152, 0.18);
}

.notstarted-badge{
  color: var(--1-primary-colors-yellow, #F4B532) !important;
  background: rgba(244, 181, 50, 0.18);
}

.notaccepted-badge{
  color: var(--1-primary-colors-red, #ED483D) !important;
  background: rgba(237, 72, 61, 0.18);
}

.revoked-badge{
  color: var(--3-text-colors-black, #000) !important;
  background: var(--3-text-colors-mid-grey, #CCC);
}

.progressbar{
  width: 160px;
}

.safe-workspace-wrapper.manage-user .dropdown-toggle[aria-expanded="true"]::after, 
.safe-workspace-wrapper.manage-user .dropdown-toggle[aria-expanded="false"]::after {
  filter: invert(1);
}

#filter-select {
  border: none;
  outline: none;
}

#orgSizeSelect{
  z-index: 99;
}

@media screen and (min-width: 1024px) {
  .reminders.dropdown-toggle::after {
    top: 11px;
}}

@media screen and (min-width: 1440px) {
  .reminders.dropdown-toggle::after {
    top: 14px;
}}
  