.bg-login {
    background-image: url('../../../public/images/login_background1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logo {
    padding-top: 30px;
    padding-left: 57px;
    width: 230px;
    height: auto;
    position: absolute;
    left: 0;
    top: 10px;
}

.login-bottom {
    border-bottom: 1px solid #E1E1E1;
}

.login-title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.957px;
}

.login-details p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.login-details .org-logo {
    max-width: 200px;
    max-height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: auto;
    object-fit: fill;
    
}

.login-details .org-logo img {
    width: 100%;
    height: auto;
}

.login-otp .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #146B90;
    --bs-btn-border-color: #146B90;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #146B90;
    --bs-btn-hover-border-color: #146B90;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146B90;
    --bs-btn-active-border-color: #146B90;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #146B90;
    --bs-btn-disabled-border-color: #146B90;
}

.login-otp .btn {
    --bs-border-radius: 4px;
}

.login-otpinput {
    padding-left: 25px;
}

.login-resend {
    float: none;
    color: #146B90;
    font-size: 18px;
}

.login-time {
    float: none;
    font-size: 20px;
}

.resend-otp {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-btn {
    width: 75%;
    margin-bottom: 8px;
    margin-top: 85px;
    font-size: 1.125rem !important;
}

.login-note {
    font-size: 14px;
    text-align: center;
    color: #666666EE;
    margin-left: 20px;
}

.login-term {
    font-weight: 700;
    text-decoration: underline;
}

.login-footer {
    padding: 0px 50px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ddd;
}

.login-copyright {
    font-size: 16px;
    color: #5A5A5A;
    letter-spacing: 1.33px;
    line-height: 35px;
}

.login-link {
    color: #000;
    text-decoration: none;
    margin-right: 25px;
    font-size: 16px;
    line-height: 25px;
}

.login-social-media {
    float: right;
}

.login-social-icons {
    width: 34px;
    margin-left: 20px;
}

.otp {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: end;
    width: 100%!important;
}

@media screen and (max-width: 1024px) {
    .otp-input > div > div > input{
        width: 50px !important;
        height: 50px !important;
        margin: 6px !important;
    }
    .login-details .org-logo {
        max-width: 150px;
        max-height: 150px;
    }
    .login-social-icons {
        width: 30px;
        margin-left: 16px;
    }
    .login-link {
        margin-right: 12px;
        font-size: 15px;
        line-height: 25px;
    }
    .login-copyright {
        font-size: 15px;
        line-height: 1.5;
    }
}

@media screen and (max-width: 1366px) {
    .otp-input > div > div > input{
        width: 50px !important;
        height: 50px !important;
        margin: 8px !important;
    }
    .login-details .org-logo {
        max-width: 150px;
        max-height: 150px;
        width: 100%;
        height: auto;
    }
    .login-details .org-logo img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

@media screen and (max-width: 992px) {
.otp-input > div > div > input{
    width: 40px !important;
    height: 40px !important;
    margin: 10px 6px !important;
}
.bg-login {
    background-image: none;
}
.bg-login .logo {
    padding-top: 14px;
    padding-left: 25px;
    width: 150px;
    left: 0;
    top: 10px;
}
.login-details p {
    font-size: 14px;
}
.bg-login .login-title {
    font-size: 16px;
}
.login-details .org-logo {
    max-width: 100px;
    max-height: 140px;
    width: 100%;
    height: auto;
}
.login-details .org-logo img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.otp-btn{
    width: 50%;
    font-size: 0.875rem !important;
}
.login-time {
    font-size: 16px;
}
.login-resend {
    font-size: 16px;
}
.login-resend button{
    font-size: 16px;
}
.login-footer {
    padding: 0px 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ddd;
}
.login-social-media {
    float: none;
    margin: 16px auto;
    text-align: center;
}
.login-link {
    margin: 0 5px;
    font-size: 14px;
}
.login-copyright {
    font-size: 14px;
    text-align: center;
}
.login-social-icons {
    width: 25px;
    margin-left: 18px;
}
}

